@import '_utilities.scss';

.bus__notification-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bus__notification {
  position: fixed;
  top: 85%;
  z-index: 70;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 400px; // 갤럭시 폴드 대응
  height: 22vw;
  max-height: 100px; // 갤럭시 폴드 대응
  background-color: darken($yellow-00, 15%);
  border-radius: clamp(1px, 6.41vw, 30px); // 갤럭시 폴드 대응
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  @include desktop {
    top: 80%;
    width: 350px;
    max-width: none;
    height: 100px;
    max-height: none;
    border-radius: 30px;
  }
  > div:nth-child(1) {
    font-size: clamp(1px, 6vw, 30px); // 갤럭시 폴드 대응
    @include desktop {
      font-size: 25px;
    }
  }
  > div:nth-child(2) {
    font-size: clamp(1px, 4vw, 22px); // 갤럭시 폴드 대응
    margin-top: clamp(1px, 1.4vw, 7px); // 갤럭시 폴드 대응
    @include desktop {
      font-size: 20px;
      margin-top: 10px;
    }
  }
}
