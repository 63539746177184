@import '_utilities.scss';

.menu-box {
  display: -webkit-flex; // 웹킷
  display: flex;
  justify-content: center;
}
.menu-box__mask {
  background-color: #000;
  opacity: 0.8;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 90;
}
.menu-box__menus {
  position: fixed;
  top: 30vh;
  z-index: 100;
  display: -webkit-flex; // 웹킷
  display: flex;
  -webkit-flex-direction: column; // 웹킷
  flex-direction: column;
  -webkit-align-items: center; // 웹킷
  align-items: center;
  font-size: clamp(1px, 10.26vw, 43px); // 갤럭시 폴드 대응
  letter-spacing: 0.51vw;
  color: $gray-00;
}
.menu-box__menu {
  margin-top: clamp(1px, 2.56vw, 12px); // 갤럭시 폴드 대응
  margin-bottom: clamp(1px, 2.56vw, 12px); // 갤럭시 폴드 대응
  text-decoration: none;
  transition: all 50ms ease-in-out;
  -webkit-transition: all 50ms ease-in-out; // Webkit prefix
  -moz-transition: all 50ms ease-in-out; // Mozilla prefix
  -o-transition: all 50ms ease-in-out; // Opera prefix
  -ms-transition: all 50ms ease-in-out; // IE prefix
  &:active {
    transform: scale(1.3);
    -webkit-transform: scale(1.3); // Webkit prefix
    -moz-transform: scale(1.3); // Mozilla prefix
    -o-transform: scale(1.3); // Opera prefix
    -ms-transform: scale(1.3); // IE prefix
  }
}
// 활성화된 라우터 표시
.menu-box__menu-active {
  margin-top: clamp(1px, 2.56vw, 12px); // 갤럭시 폴드 대응
  margin-bottom: clamp(1px, 2.56vw, 12px); // 갤럭시 폴드 대응
  text-decoration: underline;
  text-decoration-style: double;
  text-decoration-thickness: clamp(1px, 0.38vw, 1.6px); // 갤럭시 폴드 대응
  transition: all 50ms ease-in-out;
  -webkit-transition: all 50ms ease-in-out; // Webkit prefix
  -moz-transition: all 50ms ease-in-out; // Mozilla prefix
  -o-transition: all 50ms ease-in-out; // Opera prefix
  -ms-transition: all 50ms ease-in-out; // IE prefix
  &:active {
    transform: scale(1.3);
    -webkit-transform: scale(1.3); // Webkit prefix
    -moz-transform: scale(1.3); // Mozilla prefix
    -o-transform: scale(1.3); // Opera prefix
    -ms-transform: scale(1.3); // IE prefix
  }
}
.menu-box__exit {
  font-size: clamp(1px, 3.85vw, 16px); // 갤럭시 폴드 대응
  font-weight: 900;
  letter-spacing: 0.51vw;
  margin-top: clamp(1px, 12.82vw, 53px); // 갤럭시 폴드 대응
  padding: clamp(1px, 1.79vw, 8px); // 갤럭시 폴드 대응
  border: 1px solid $gray-00;
  -moz-border-radius: 1.28vw;
  -webkit-border-radius: 1.28vw;
  border-radius: 1.28vw;
  -moz-transition: transform 50ms ease-in-out;
  -webkit-transition: transform 50ms ease-in-out;
  transition: transform 50ms ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &:active {
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
