@import '_utilities.scss';

.pop-up-map {
  display: -webkit-flex; // 웹킷
  display: flex;
  justify-content: center;
  .pop-up-map__mask {
    background-color: #000;
    opacity: 0.8;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 90;
  }
  .pop-up-map__kakao-map--wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 100;
    display: -webkit-flex; // 웹킷
    display: flex;
    -webkit-flex-direction: column; // 웹킷
    flex-direction: column;
    -webkit-align-items: center; // 웹킷
    align-items: center;
    width: 100%;
    @include desktop {
      width: 65%;
    }
    // 지도
    > div:first-child {
      isolation: isolate;
      max-height: 420px;
      border-radius: 10.26vw;
      height: 60%;
      @include desktop {
        max-height: none;
        height: 500px;
        border-radius: 35px;
      }
    }
    .pop-up-map__location {
      padding: 0 10vw;
      margin-top: 2.5vw;
      font-size: clamp(1px, 5vw, 25px); // 갤럭시 폴드 대응
      line-height: 6.5vw;
      text-align: center;
      letter-spacing: 0.3vw;
      word-break: keep-all; // 단어기준으로 줄바꿈
      @include desktop {
        padding: 0 10%;
        margin-top: 10px;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 1px;
      }
    }
    .pop-up-map__close {
      font-size: clamp(1px, 3.85vw, 16px); // 갤럭시 폴드 대응
      font-weight: 900;
      letter-spacing: 0.51vw;
      margin-top: clamp(1px, 2.5vw, 11px); // 갤럭시 폴드 대응
      padding: clamp(1px, 1.79vw, 8px);
      border: 1px solid $gray-00;
      border-radius: 1.28vw;
      -moz-transition: transform 50ms ease-in-out;
      -webkit-transition: transform 50ms ease-in-out;
      transition: transform 50ms ease-in-out;
      @include desktop {
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 5px;
        margin-top: 11px;
        padding: 8px 3px 8px 8px;
        border: 1px solid $gray-00;
        border-radius: 10px;
      }
      &:hover {
        cursor: pointer;
      }
      &:active {
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
  }
}
