@import '_utilities.scss';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10.26vw;
  max-height: 43px; // 갤럭시 폴드 대응
  margin-top: 3.33vw;
  border-top: 1px solid $gray-08;
  color: $gray-06;
  @include desktop {
    max-height: none;
    width: 100%;
    height: 65px;
    margin-top: 30px;
  }
}
